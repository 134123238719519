<template>
  <c-tab
    type="pagetop"
    :tabItems.sync="tabItems"
    :dense="true"
    :inlineLabel="true"
    v-model="tab"
    align="left"
  >
    <template v-slot:default="tab">
      <component
        :is="tab.component"
      />
    </template>
  </c-tab>
</template>

<script>
export default {
  name: 'patrolGraph',
  data() {
    return {
      tab: 'month',
      tabItems: [
        // 월별 점검결과 현황
        { name: 'month', icon: 'bar_chart', label: '월별 점검결과 현황', component: () => import(`${'./patrolGraphMonth.vue'}`) },
        // 점검항목별 통계
        { name: 'result', icon: 'query_stats', label: '점검항목별 통계', component: () => import(`${'./patrolGraphResult.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>
